import Swiper, { Scrollbar, Navigation } from 'swiper';

Swiper.use([Scrollbar, Navigation]);

const swiper = new Swiper('.productSlider', {
    spaceBetween: 8,
    slidesPerView: 'auto',
    navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
    },
    breakpoints: {
        640: {
            spaceBetween: 12,
            slidesPerView: 2,
        },
        768: {
            spaceBetween: 16,
            slidesPerView: 3,
        },
        1024: {
            spaceBetween: 24,
            slidesPerView: 4,
        },
        1280: {
            spaceBetween: 32,
        },
        1536: {
            spaceBetween: 40,
        },
    },
});