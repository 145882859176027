import Swiper, { Scrollbar, Navigation } from 'swiper';

Swiper.use([Scrollbar, Navigation]);

new Swiper('.categorySlider', {
    modules: [Scrollbar],
    spaceBetween: 8,
    slidesPerView: 'auto',
    freeMode: {
        enabled: true,
        momentum: true,
        momentumRatio: 2,
        momentumVelocityRatio: 2,
        sticky: true
    },
    navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
    },
    scrollbar: {
        el: '.swiper-scrollbar'
    },
    breakpoints: {
        768: { spaceBetween: 16 },
        1024: { spaceBetween: 24 },
        1280: { spaceBetween: 32 },
        1536: { spaceBetween: 40 },
    },
});