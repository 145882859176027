export default () => ({
    open: true,
    key: 'alert-banner',

    init() {
        var item = sessionStorage.getItem(this.key);
        this.open = item == null || item == "true";
    },

    hideAlertBanner() {
        this.open = false;
        sessionStorage.setItem(this.key, this.open);
    }
});