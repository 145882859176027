// Javascript
import './components/HomeSlider.js';
import './components/CategorySlider.js';
import './components/ProductDetailSlider.js';
import './components/ProductSlider.js';

// AlpineJS
import Alpine from 'alpinejs';
import Notification from './components/Notification.js';
 
window.Alpine = Alpine;

Alpine.store("menu", {
    sticky: false,
    active: false,

    init () {
        addEventListener("scroll", () => {
            this.sticky = window.pageYOffset > 40;
        });
    }
})

Alpine.data("Notification", Notification);
 
Alpine.start();

// // GSAP
// import { gsap } from "gsap";
// import { ScrollTrigger } from "gsap/ScrollTrigger";

// gsap.registerPlugin(ScrollTrigger);

// gsap.set(".card", {
//     y: '25%',
//     opacity: 0,
// })

// ScrollTrigger.batch(".card", {
//     onEnter: batch => gsap.to(batch, {
//         duration: 0.5,
//         stagger: 0.075,
//         y: '0',
//         opacity: 1,
//         ease: 'power2.easeOut',
//     })
// });

// gsap.set(".gsap-title", {
//     y: '100%',
// });

// gsap.to(".gsap-title", {
//     scrollTrigger: ".gsap-trigger",
//     y: 0,
//     delay: 0.25,
//     ease: 'power2.easeOut',
// });