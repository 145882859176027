import Swiper, { Scrollbar, Thumbs, Navigation } from 'swiper';

Swiper.use([Scrollbar, Thumbs, Navigation]);

// Productdetailpagina thumbnails
const thumbs = new Swiper('.productThumbnailsSlider', {
    spaceBetween: 8,
    slidesPerView: 'auto',
    watchSlidesProgress: true,
});

// Productdetailpagina
const swiper = new Swiper('.productDetailSlider', {
    spaceBetween: 16,
    slidesPerView: 1,
    thumbs: {
        swiper: thumbs
    },
    navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
    },
    breakpoints: {
        768: { slidesPerView: 'auto' }
    },
});